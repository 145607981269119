import { throttle } from 'lodash';

let $window = $(window);
let $document = $(document);
let $html = $('html');
let $headerTop = $('.header-top');
let $headerNote = $('.header-note');
let scroll, position, minPosition, pageIsScolledDown;

if ($headerNote.length) {
    $html.addClass('-has-header-note');
}

$window.on('scroll resize load', throttle(() => {
    scroll = $window.scrollTop();
    position = $window.scrollTop() + $window.height();
    minPosition = $document.height() - 300;
    pageIsScolledDown = scroll > 0;

    $html.toggleClass('-page-is-scrolled-down', pageIsScolledDown);
    $html.toggleClass('-should-show-scroll-up', scroll > 200 && position > minPosition);

    if (pageIsScolledDown) {
        if ($headerNote.length) {
            $headerNote.css('margin-top', '-' + $headerNote.outerHeight() + 'px');
        }

        $headerTop.css('margin-top', '-' + $headerTop.outerHeight() + 'px');
    } else {
        if ($headerNote.length) {
            $headerNote.removeAttr('style');
        }

        $headerTop.removeAttr('style');
    }
}, 20));

// Loading class
$document.ajaxStart(() => {
    $html.addClass('-has-xhr-activity');
}).ajaxComplete(() => {
    $html.removeClass('-has-xhr-activity');
});
