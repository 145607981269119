// Whooohoo!
let ElementRoutes = {
    '.boxy9-main-image' () {
        require.ensure([], () => {
            require('./scenes/boxy9');
        });
    },
    '.scrollmagic-banner-parallax' () {
        require.ensure([], () => {
            require('./scenes/banner-parallax');
        });
    },
    '.scrollmagic-bg-parallax1' () {
        require.ensure([], () => {
            require('./scenes/bg-parallax1');
        });
    },
    '.scrollmagic-list1' () {
        require.ensure([], () => {
            require('./scenes/list1');
        });
    },
    '.scrollmagic-list7' () {
        require.ensure([], () => {
            require('./scenes/list7');
        });
    },
    '.scrollmagic-parallax1' () {
        require.ensure([], () => {
            require('./scenes/parallax1');
        });
    },
    '.scrollmagic-zoom' () {
        require.ensure([], () => {
            require('./scenes/zoom');
        });
    },
};

$.each(ElementRoutes, (key, callback) => {
    if (document.querySelectorAll(key).length) {
        callback();
        console.info('✓ scene: ' + key);
    }
});
