import './floatbar.scss'
import { throttle } from 'lodash'

const $hideFromEl = $('[data-floatbar-hide-from-here]')

if ($hideFromEl.length > 0) {
    const $window = $(window)
    const $bar = $('[id="floatbar"]')

    $window.on('scroll resize load', throttle(() => {
        $bar.attr('aria-hidden', $window.scrollTop() > $hideFromEl.position().top - $window.height())
    }, 100))
}


