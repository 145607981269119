import 'magnific-popup'

$(() => {
    $('.card4-image').magnificPopup({
        type: 'image',
        image: { verticalFit: true },
        mainClass: 'mfp-img-mobile',
        closeOnContentClick: true,
    })
})
