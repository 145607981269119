import { debug } from '../core';
import analytics from '../analytics';
import { scrollTo } from '../utils';
import swal from '../sweetalert';

function Form () {
    this.el = null;
    this.options = {
        busyClassname: '-has-xhr-activity',
        disableableSelector: 'input, textarea, select, button',
        errorMessage: 'Sorry, an error occured. Please try again later.'
    };

    if (arguments.length) {
        this.initialize.apply(this, arguments);
    }
}

Form.prototype.initialize = function (element, options) {
    this.el = $(element);
    this.el.on('submit', _.bind(this.onSubmit, this));

    if (typeof options === 'object') {
        let _this = this;
        this.options = $.extend({}, _this.options, options);
    }

    if (debug()) {
        this.prefill();
    }
};

Form.prototype.onSubmit = function (event) {
    event.preventDefault();
    this.send();
};

Form.prototype.disableForm = function () {
    this.el
        .addClass(this.options.busyClassname)
        .find(this.options.disableableSelector)
        .attr('disabled', 'disabled');
};

Form.prototype.enableForm = function () {
    this.el
        .removeClass(this.options.busyClassname)
        .find(this.options.disableableSelector)
        .removeAttr('disabled');
};

Form.prototype.resetForm = function () {
    this.el.find('input, textarea, select')
        .not('.btn')
        .not('[type=checkbox]')
        .not('[type=hidden]')
        .not('[type=radio]')
        .val('');

    this.el.find('[type=checkbox]').removeAttr('checked');
    this.el.trigger('_reset_');
};

Form.prototype.send = function () {
    let self = this;

    $.ajax({
        url: this.el.attr('action'),
        type: 'POST',
        data: new FormData(self.el[0]),
        cache: false,
        contentType: false,
        processData: false,
        beforeSend() {
            self.disableForm();
            self.resetErrors();
        },
        complete() {
            self.enableForm();
        },
        success: _.bind(self.onMessageSend, self),
        error: _.bind(self.onFailure, self)
    });
};

Form.prototype.onMessageSend = function (response) {
    this.resetForm();

    analytics.sendPageview(this.el.attr('action'));

    if (response.redirectUrl) {
        window.location.href = response.redirectUrl;
    } else {
        swal({
            title: response.title,
            text: response.message,
            type: 'success'
        });
    }
};

Form.prototype.onFailure = function (jqXHR) {
    let response = jqXHR.responseJSON || {};
    let errors = [];
    let errorsObject = response.errors || response;

    _.each(errorsObject, (err, name) => {
        this.highlightErrorOnField(name, err);
        errors = errors.concat(err);
    });

    if (0 === errors.length) {
        errors.push(response.message || this.options.errorMessage);
    }

    this.displayErrors(_.uniq(errors));
    this.scrollToFirstError();
};

Form.prototype.displayErrors = function (errors) {
    swal({
        title: '',
        html: errors.join('<br>'),
        type: 'warning'
    });
};

Form.prototype.highlightErrorOnField = function (fieldName) {
    let elem = this.el.find('[name="' + fieldName + '"]:visible').first();
    if (elem.length) {
        elem.closest('.form-group').addClass('-has-error');
    }
};

Form.prototype.scrollToFirstError = function () {
    let elem = this.el.find('.-has-error:first');
    if (elem.length) {
        scrollTo(elem.offset().top - 5);
    } else {
        scrollTo(this.el.offset().top - 5);
    }
};

Form.prototype.resetErrors = function () {
    let elements = this.el.find('.-has-error');
    if (elements.length) {
        elements.removeClass('-has-error');
    }
};

// Form.prototype.prefill = function () {
//     let values = this.options.dummyData;
//
//     _.each(values, (value, name) => {
//         this.el.find('[name="' + name + '"]').val(value);
//     });
// };

export default Form;
