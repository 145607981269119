import Isotope from 'isotope-layout/dist/isotope.pkgd.min';
import imagesLoaded from 'imagesloaded';

$(() => {
    let $list = $('.lazyload-isotope');

    let iso = new Isotope($list[0], {
        layoutMode: 'masonry',
        itemSelector: '.isotope-item',
        masonry: {
            gutter: '.grid-gutter',
            percentPosition: true,
        }
    });

    imagesLoaded($list[0], function () {
        iso.layout();
    });

    let $button = $list.parent().find('[data-lazyload-trigger]');
    let page = 0;
    let used = $list.data('used');

    if ($button.length) {
        $button.on('click touchstart', function (event) {
            event.preventDefault();

            $.ajax({
                url: '/api' + window.location.pathname + window.location.search,
                data: { page: ++page, used },
                dataType: 'json',
            }).done((paginator) => {
                $.each(paginator.data, function(i, item) {
                    let $item = $(item);
                    item = $item[0]; // convert string
                    $list.append(item);
                    $item.hide();

                    imagesLoaded(item, function () {
                        iso.appended(item);
                        iso.layout();
                    });
                });

                if (paginator.current_page === paginator.last_page) {
                    $button.remove();
                }
            });
        });
    }
});
