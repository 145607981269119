import $ from 'jquery';
import 'jquery.mmenu/dist/jquery.mmenu.all';
import './mobile.scss';

$(() => {
    let menuElement = $('[id="menu"]');
    let socialIconsClone = $('#bottom .social-icons').clone();
    let logoClone = $('.js-menu-logo').clone();
    let clone = menuElement.clone();

    clone.attr('id', 'mmenu');

    clone.mmenu({
        extensions: [
            'pagedim-black',
            'shadow-page',
            'fx-menu-slide',
            'fx-listitems-slide',
        ],
        offCanvas: {
            position: 'left',
            zposition: 'front'
        }
    }, {
        //
    });

    clone.find('#mm-1 .mm-title').text('').append(logoClone);

    clone.find('#mm-1').append(socialIconsClone);

    socialIconsClone.click('a', () => {
        api.close();
    });

    let api = clone.data('mmenu');
    let hamburger = document.querySelector('.hamburger');

    api.bind('open:start', () => {
        setTimeout(() => {
            $(hamburger).addClass('is-active');
        }, 100);
    });

    api.bind('close:start', () => {
        setTimeout(() => {
            $(hamburger).removeClass('is-active');
        }, 100);
    });
});
