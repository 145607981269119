// import 'es6-promise'; // IE >= 10 support for sweetalert2
import swal from 'sweetalert2';

// Set Sweet Alert defaults
swal.mixin({
    cancelButtonText: 'Annuleren',
    confirmButtonColor: '#ffcb25',
    // cancelButtonColor: '#d3d3d3',
    confirmButtonText: 'OK!'
});

export default swal;
