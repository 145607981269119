export default function (listElement) {
    const $bodies = listElement.find('[class$=-body]')

    let highestHeight = 0
    $bodies.removeAttr('style')

    $bodies.each((i, e) => {
        let bodyHeight = $(e).outerHeight()

        if (bodyHeight > highestHeight) {
            highestHeight = bodyHeight
        }
    });

    $bodies.css('height', highestHeight + 'px')
}
