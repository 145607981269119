import { throttle } from 'lodash';
import setBodyHeight from './set-body-height';

let $lists = $('.list1, .list2');

$(window).on('load resize', throttle(() => {
    $lists.each((i, e) => {
        setBodyHeight($(e));
    });
}, 20));
