import $ from 'jquery';

$(() => {
    let menus = ['[id="menu"]', '[id="submenu"]'];
    let segments = window.location.pathname.split('/').slice(1);
    let selectors = [];
    let path;

    if (['openbanendag', 'werken-bij'].indexOf(segments[0])) {
        return
    }

    if (1 === segments.length) {
        path = segments[0];
    } else if (1 < segments.length) {
        path =  segments.slice(0, segments.length).join('/');
    }

    if (path) {
        $.each(menus, (index, selector) => {
            selectors.push(selector + ' a[href^="/' + path + '"]:first');
        });
    }

    let linkElem = $(selectors.join(','));

    if (linkElem.length) {
        let parentLi = linkElem.closest('li').addClass('current');
        let upperLi = parentLi.closest('ul').parent('li');

        while (upperLi.length) {
            upperLi.addClass('current');
            upperLi = upperLi.closest('ul').parent('li');
        }
    }
});

