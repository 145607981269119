/**
 * Photoswipe
 * @author  Jürgen van Dijk <j.vandijk@activecreations.nl>
 */
/*global define*/
define([
    'jquery',
    'photoswipe/dist/photoswipe.min.js',
    'photoswipe/dist/photoswipe-ui-default.min.js',
    'raw-loader!./template.html',
    './stylesheet.scss'
], function ($, PhotoSwipe, PhotoSwipeUI_Default, template) {

    // Initialize function
    var initPhotoSwipeFromDOM = function (gallerySelector) {

        // parse slide data (url, title, size ...) from DOM elements
        // (children of gallerySelector)
        var parseThumbnailElements = function(el) {
            var thumbElements = el.childNodes,
                numNodes = thumbElements.length,
                items = [],
                figureEl,
                linkEl,
                size,
                item;

            for (var i = 0; i < numNodes; i++) {
                figureEl = thumbElements[i]; // <figure> element

                // include only element nodes
                if (figureEl.nodeType !== 1) {
                    continue;
                }

                // element should have children
                if (0 === figureEl.children.length || figureEl.tagName.toUpperCase() !== 'FIGURE' || $(figureEl).hasClass('cycle-sentinel')) {
                    continue;
                }

                linkEl = figureEl.children[0]; // <a> element

                size = linkEl.getAttribute('data-size').split('x');

                // create slide object
                item = {
                    index: figureEl.getAttribute('data-index'),
                    src: linkEl.getAttribute('href'),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };

                if (figureEl.children.length > 1) {
                    // <figcaption> content
                    item.title = figureEl.children[1].innerHTML;
                }

                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute('src');
                }

                item.el = figureEl; // save link to element for getThumbBoundsFn
                items.push(item);
            }

            return items;
        };

        // find nearest parent element
        var closest = function closest(el, fn) {
            return el && (fn(el) ? el : closest(el.parentNode, fn));
        };

        // triggers when user clicks on thumbnail
        var onThumbnailsClick = function(e) {
            e = e || window.event;
            var eTarget = e.target || e.srcElement;

            //if ('true' === eTarget.getAttribute('data-follow-link')) {
            //    return true;
            //}

            e.preventDefault ? e.preventDefault() : e.returnValue = false;

            // find root element of slide
            var clickedListItem = closest(eTarget, function(el) {
                return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
            });

            if (!clickedListItem) {
                return;
            }

            // find index of clicked item by looping through all child nodes
            // alternatively, you may define index via data- attribute
            var clickedGallery = clickedListItem.parentNode,
                // childNodes = clickedListItem.parentNode.childNodes,
                // numChildNodes = childNodes.length,
                // nodeIndex = 0,
                index;

            index = parseInt(clickedListItem.getAttribute('data-index'));

            //for (var i = 0; i < numChildNodes; i++) {
            //    // origineel: if (childNodes[i].nodeType !== 1) {
            //    if (childNodes[i].nodeType !== 1 || 0 === childNodes[i].children.length) {
            //        continue;
            //    }
            //
            //    if (childNodes[i] === clickedListItem) {
            //        index = nodeIndex;
            //        break;
            //    }
            //
            //    nodeIndex++;
            //}

            if (index >= 0) {
                // open PhotoSwipe if valid index found
                openPhotoSwipe(index, clickedGallery);
            }
            return false;
        };

        // parse picture index and gallery index from URL (#&pid=1&gid=2)
        var photoswipeParseHash = function() {
            var hash = window.location.hash.substring(1),
                params = {};

            if (hash.length < 5) {
                return params;
            }

            var vars = hash.split('&');
            for (var i = 0; i < vars.length; i++) {
                if (!vars[i]) {
                    continue;
                }
                var pair = vars[i].split('=');
                if (pair.length < 2) {
                    continue;
                }
                params[pair[0]] = pair[1];
            }

            if (params.gid) {
                params.gid = parseInt(params.gid, 10);
            }

            if (!params.hasOwnProperty('pid')) {
                return params;
            }
            params.pid = parseInt(params.pid, 10);
            return params;
        };

        var openPhotoSwipe = function(index, galleryElement, disableAnimation) {
            var pswpElement = document.querySelectorAll('.pswp')[0],
                gallery,
                options,
                items;

            items = parseThumbnailElements(galleryElement);

            /* -- Find given index on items -- */
            var itemsIndex = false;

            $.each(items, function (i, item) {
                if (item.index === index) {
                    itemsIndex = i;
                }
            });

            if (itemsIndex) index = itemsIndex;
            /* -- */

            // define options (if needed)
            options = {
                index: index,

                // define gallery index (for URL)
                galleryUID: galleryElement.getAttribute('data-pswp-uid'),

                getThumbBoundsFn: function(index) {
                    // See Options -> getThumbBoundsFn section of documentation for more info
                    var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
                        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                        rect = thumbnail.getBoundingClientRect();

                    return {
                        x: rect.left,
                        y: rect.top + pageYScroll,
                        w: rect.width
                    };
                },

                // Lazy loading of nearby slides based on direction of movement
                preload: [1, 3]

            };

            if (disableAnimation) {
                options.showAnimationDuration = 0;
            }

            // Pass data to PhotoSwipe and initialize it
            gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        };

        // loop through all gallery elements and bind events
        var galleryElements = document.querySelectorAll(gallerySelector);

        for (var i = 0, l = galleryElements.length; i < l; i++) {
            galleryElements[i].setAttribute('data-pswp-uid', i + 1);
            galleryElements[i].onclick = onThumbnailsClick;
        }

        // Parse URL and open gallery if it contains #&pid=3&gid=1
        var hashData = photoswipeParseHash();
        if (hashData.pid > 0 && hashData.gid > 0) {
            openPhotoSwipe(hashData.pid - 1, galleryElements[hashData.gid - 1], true);
        }
    };

    // Initialize when dom is ready
    $(function () {
        $('body').append(template.default);

        setTimeout(() => {
            initPhotoSwipeFromDOM('.gallery');
        }, 500);
    });

});
