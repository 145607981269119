/*global require*/
import Vue from 'vue';
import $ from 'jquery';
import { csrf_token } from './js/core';

// Styles
import './sass/styles.scss';

// Scripts
import './js/modernizr';
import 'picturefill';

import analytics from './js/analytics';
import './js/animations';
import './js/layout';
import './js/magnific-popup';
import './js/menu';
import './js/scroll-helpers';
import './js/data-href-link';
import './js/scrollmagic';

// Initialize analytics
analytics.initialize();

// Vue
window.Vue = Vue

Vue.component('vacatures', () => import('./vue/components/vacatures'))
Vue.component('fileupload', () => import('./vue/components/fileupload'))
Vue.component('quote-slider', () => import('./vue/components/quote-slider'))

// eslint-disable-next-line
new Vue({ el: '#wrapper' })

// Whooohoo, lets go!
let ElementRoutes = {
    '.js-carousel1' () { require('./js/carousel1') },
    'form.form-generic' () { require('./js/forms/generic') },
    '.gallery' () { require('./js/photoswipe') },
    'img[data-src]' () { require('./js/unveil') },
    '.js-video-popup' () { require('./js/video-popup') },
    '.lazyload' () { require('./js/lazyload') },
    '.lazyload-isotope' () { require('./js/lazyload/isotope') },
    '.list1, .list2' () { require('./js/list-body-height') },
    '[id="floatbar"]' () { require('./js/floatbar') },
    '[id="popup"]'() { require('./js/popup') },
};

$.each(ElementRoutes, (key, callback) => {
    if ($(key).length) {
        callback();
        console.info('✓ ' + key);
    }
});

// CSRF Header to ajax request
$.ajaxSetup({ headers: { 'X-CSRF-TOKEN': csrf_token() } });

// Trigger click helper
$(document).on('click touchstart', '[data-trigger-click]', function (e) {
    try {
        e.preventDefault();
        $(this.getAttribute('data-trigger-click')).trigger('click');
    } catch (e) {
        //
    }
});

// Op touchdevices niet bij scrollen de gallery openen
document.querySelectorAll('.photosbox').forEach(item => {
    item.addEventListener('touchstart', event => {
        event.stopPropagation()
    })
})

// Test for iPad or iPhone
if (navigator.userAgent.match(/(iPad|iPhone)/g)) {
    $('html').addClass('-is-ios');
}
