const Popup = {
  element: null,
  close() {
    $(this.element).fadeOut()
  },
  initialize(element) {
    this.element = element
    this.setBindings()
    this.setCookie()
  },
  setBindings() {
    document.addEventListener('keyup', event => {
      if (event.key === 'Escape') {
        this.close()
      }
    })

    this.element.onclick = () => {
      this.close()
    }

    const button = this.element.querySelector('a')

    if (button !== null) {
      button.onclick = event => {
        event.stopPropagation()
      }
    }
  },
  setCookie() {
    const cookie = this.element.dataset['cookie']
    const interval = this.element.dataset['interval']

    const date = new Date()
    date.setTime(date.getTime() + interval * 60 * 1000)
    const expires = date.toGMTString()
    const offset = -date.getTimezoneOffset() / 60

    document.cookie = `${cookie}=true; timezone='${offset}'; expires='${expires}'; path=/`
  },
}

Popup.initialize(document.getElementById('popup'))
