import imagesLoaded from 'imagesloaded';
import setBodyHeight from '../list-body-height/set-body-height';

$(() => {
    let $list = $('.lazyload');

    let $button = $list.parent().find('[data-lazyload-trigger]');
    let page = 0;
    let used = $list.data('used');

    if ($button.length) {
        $button.on('click touchstart', function (event) {
            event.preventDefault();

            $.ajax({
                url: '/api' + window.location.pathname + window.location.search,
                data: { page: ++page, used },
                dataType: 'json',
            }).done((paginator) => {
                let $items = $(paginator.data.join(''));
                $list.append($items);
                $items.hide();

                imagesLoaded($items, function () {
                    $items.slideDown();

                    if($list.hasClass('list1') || $list.hasClass('list2')) {
                        setBodyHeight($list);
                    }
                });

                if (paginator.current_page === paginator.last_page) {
                    $button.remove();
                }
            });
        });
    }
});
