import $ from 'jquery';
import './styles.css';

class Clickable {
    constructor (element) {
        this.el = $(element);
        this.initialize();
    }

    initialize () {
        if (this.href == '') {
            this.el.removeAttr('data-href-link');
        }
    }

    get href () {
        const href = this.el.data('href-link');

        if (0 === href.length) {
            const button = this.el.find('a:first');

            if (button.length && button.attr('href').length) {
                return button.attr('href');
            }
        }

        return href;
    }

    get target () {
        const button = this.el.find('a:first');

        if (button.length) {
            const targetAttr = button.attr('target');

            if (typeof targetAttr !== 'undefined' && targetAttr !== false && targetAttr.length) {
                return button.attr('target');
            }
        }

        return '_self';
    }

    onClick (event) {
        const href = this.href;

        if (href) {
            // Requested to open in new tab
            if (2 === event.which || event.metaKey || event.ctrlKey) {
                window.open(href, '_blank');
            } else {
                window.open(href, this.target);
            }
        }
    }
}

$('[data-href-link]').each(function () {
    $(this).data('clickable', new Clickable(this));
});

$(document).on('click tap', '[data-href-link]', function (event) {
    event.preventDefault();
    const clickable = $(this).data('clickable') || new Clickable(this);
    clickable.onClick(event);
});
