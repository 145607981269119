/*global gtag*/
// @see: https://developers.google.com/analytics/devguides/collection/gtagjs/migration#track_pageviews_with_the_default_tracker

const analytics = {
    initialize () {
        if ('function' !== typeof gtag) {
            console.warn('Google Tag Manager is not detected but it might be loading async..')
        } else {
            console.info('Google Tag Manager has been detected.')
        }
    },

    // @see: https://developers.google.com/analytics/devguides/collection/gtagjs/events
    sendEvent (event_category, action, event_label = null, value = null) {
        try {
            gtag('event', action, { event_category, event_label, value })
        } catch (error) {
            console.warn('Failed to call gtag() with arguments: ', arguments)
        }
    },

    sendPageview (page) {
        try {
            gtag('send', 'pageview', page)
        } catch (error) {
            console.warn('Failed to call gtag() with arguments: ', arguments)
        }
    },
}

export let initialize = analytics.initialize
export let sendEvent = analytics.sendEvent
export let sendPageview = analytics.sendPageview

export default analytics
