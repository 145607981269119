import $ from 'jquery';
import 'magnific-popup/dist/jquery.magnific-popup.js';

$(() => {
    $('.js-video-popup').magnificPopup({
        type: 'iframe',
        iframe: {
            patterns: {
                youtube: {
                    index: 'youtube.com/',
                    id: 'v=',
                    src: '//www.youtube.com/embed/%id%?autoplay=1'
                },
                vimeo: {
                    index: 'vimeo.com/',
                    id: '/',
                    src: '//player.vimeo.com/video/%id%?autoplay=1'
                },
            },
            srcAction: 'iframe_src',
        }
    });
});