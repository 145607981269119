import '../slick-carousel';
import { throttle } from 'lodash';

$('.js-carousel1').each(function (index, element) {
    let $carousel = $(element);

    $carousel.slick({ speed: 1500, arrows: false });

    $carousel.find('button.btn').on('click', () => {
        $carousel.slick('slickNext');
    });

    $(window).on('load resize', throttle(() => {
        let $boxies = $carousel.find('.boxy5');
        let highestHeight = 0;

        $boxies.css('height', 'auto');

        $boxies.each((i, e) => {
            let boxyHeight = $(e).outerHeight();

            if (boxyHeight > highestHeight) {
                highestHeight = boxyHeight;
            }
        });

        $boxies.css('height', highestHeight + 'px');
    }, 20));
});

